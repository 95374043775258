// TypeScript code for defining enums related to event categories, component types, and error types.

export enum EVENT_CATEGORY {
  'COMPONENT_EVENT' = 'COMPONENT_EVENT',
  'PAGE_VIEW' = 'PAGE_VIEW',
  'APP_RESPONSE' = 'APP_RESPONSE',
  'ERROR' = 'ERROR',
  'PURCHASE' = 'PURCHASE',
  'SEARCH' = 'SEARCH',
  'FILTER_INTERACTION' = 'FILTER_INTERACTION',
  'PRODUCT_INTERACTION' = 'PRODUCT_INTERACTION',
  'PAGE_PRODUCTS_DISPLAYED' = 'PAGE_PRODUCTS_DISPLAYED',
}

declare global {
  interface Window {
    MwaAnalytics: {
      trackEvent: (name: keyof typeof EVENT_CATEGORY, data: any) => void;
      EVENT_CATEGORY: typeof EVENT_CATEGORY;
    };
  }
}

// Defining the return type of useAnalytics function
type UseAnalyticsResult = {
  trackEvent: (name: keyof typeof EVENT_CATEGORY, data: any) => ReturnType<typeof window.MwaAnalytics.trackEvent>;
  EVENT_CATEGORY: typeof EVENT_CATEGORY;
};

export const useAnalytics = (): UseAnalyticsResult => {
  const trackEvent = (name: keyof typeof window.MwaAnalytics.EVENT_CATEGORY, data: any) => {
    const eventCategory = typeof window !== 'undefined' && window?.MwaAnalytics?.EVENT_CATEGORY[name];
    if (eventCategory) {
      window.MwaAnalytics.trackEvent(eventCategory, data);
    }
  };

  return {
    trackEvent,
    EVENT_CATEGORY,
  };
};
